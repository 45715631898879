/*
 * @Description: 商城配置数据
 * @Autor: WangYuan
 * @Date: 2021-09-28 15:05:58
 * @LastEditors: WangYuan http://110.41.150.71:8090/img/1667354717861.png
 * @LastEditTime: 2022-11-02 09:44:23
 */

export const mallTypeList = [
    {
        type: 'mall',
        logo: 'https://h5manage.5izg.bj.cn/img/MallLogo.png', //初始图片配置
        title: '微商城',
        subTitle: ' E-commence'
    },
    {
        type: 'model',
        logo: 'https://h5manage.5izg.bj.cn/img/MallLogo.png', //初始图片配置
        title: '商城模板',
        subTitle: ' E-commenceModel'
    },
]

export const mallIndustryList = [
    { label: '日用百货', value: 'department' },
    { label: '服饰饰品', value: 'clothes' },
    { label: '休闲零食', value: 'snacks' },
    { label: '数码家电', value: 'digital' },
    { label: '教育培训', value: 'education' },
    { label: '酒店旅游', value: 'hotel' },
    { label: '其他', value: 'other' },
]